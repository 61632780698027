export const technology = [
  {
    type: 'ad',
    img: new URL('../assets/ads/tech_zemlerob_sponsor.gif', import.meta.url),
    imgMobile: new URL('../assets/ads/tech_zemlerob_sponsor.gif', import.meta.url),
    height: '115px',
    heightMobile: '115px',
    url: 'https://www.facebook.com/zemlerob.com.ua/',
    time: '00:00 - 00:00',
  },
  {
    type: 'report',
    time: '10:20',
    desc: 'Інноваційні мікробні біотехнології - основний ресурс формування рентабельного та прибуткового рослинництва',
    speakerName: 'Людмила Білявська',
    speakerDesc: 'Доктор біологічних наук, директор з науки ТОВ “ Лайф Біохем”',
    speakerImg: new URL('../assets/speakers/bilyavska.png?as=webp&width=200', import.meta.url),
    brandImg: new URL('../assets/speakers/andrushenko_logo.png?as=webp', import.meta.url),
  },
  {
    type: 'report',
    time: '14:20',
    desc: 'Насіння соняшнику і кукурудзи: прогнози, тренди та технологічні рішення 2025',
    speakerName: 'Ольга Молокович',
    speakerDesc: 'PhD, керівник відділу маркетингу MAS Seeds',
    speakerImg: new URL('../assets/speakers/molokovich.png?as=webp&width=200', import.meta.url),
    brandImg: new URL('../assets/speakers/molokovich_logo.png?as=webp', import.meta.url),
  },
  {
    type: 'reportMulti',
    time: '15:20',
    desc: 'Економіка обприскування: як сучасні підходи допомагають заощаджувати.',
    speakers: [
      {
        speakerName: 'Мирослав Гуранський',
        speakerDesc: 'Заступник генерального директора з технічних питань та інновацій компанії ІМК',
        speakerImg: new URL('../assets/speakers/guransky.png?as=webp&width=200', import.meta.url),
        brandImg: new URL('../assets/speakers/krivickiy_logo.png?as=webp', import.meta.url),
      },
      {
        speakerName: 'Денис Муштин',
        speakerDesc: 'Представник компанії TECNOMA в Україні',
        speakerImg: new URL('../assets/speakers/mushtin.png?as=webp&width=200', import.meta.url),
        brandImg: new URL('../assets/speakers/mushtin_logo.png?as=webp', import.meta.url),
      },
    ],
  },
  {
    type: 'reportMulti',
    time: '11:40',
    desc: 'Не більше, а розумніше: підвищуємо ефективність добрив та ЗЗР.',
    speakers: [
      {
        speakerName: 'Олександр Карнаух',
        speakerDesc: 'продакт менеджер відділу добрив і мікродобрив ГК «Агро Експерт»',
        speakerImg: new URL('../assets/speakers/alex_karnaux.png?as=webp&width=200', import.meta.url),
        brandImg: new URL('../assets/speakers/alex_karnaux_agroexpert.png?as=webp', import.meta.url),
      },
      {
        speakerName: 'Людвікас Бабаляускас',
        speakerDesc: 'Global sales director, NANDO',
        speakerImg: new URL('../assets/speakers/ludbabalyauskas.png?as=webp&width=200', import.meta.url),
        brandImg: new URL('../assets/speakers/ludbabalyauskas_logo.png?as=webp', import.meta.url),
      },
    ],
  },
  {
    type: 'reportMulti',
    time: '11:00',
    desc: 'Тема уточнюється',
    speakers: [
      {
        speakerName: 'Головатюк Максим',
        speakerDesc: 'Заступник директора комерційного з питань агробізнесу BTU',
        speakerImg: new URL('../assets/speakers/golovatyk.png?as=webp&width=200', import.meta.url),
        brandImg: new URL('../assets/speakers/golovatyk_logo.png?as=webp', import.meta.url),
      },
      {
        speakerName: 'Бухонська Ярослава',
        speakerDesc: 'Фізіолог рослин BTU',
        speakerImg: new URL('../assets/speakers/buxonska.png?as=webp&width=200', import.meta.url),
        brandImg: new URL('../assets/speakers/buxonska_logo.png?as=webp', import.meta.url),
      },
    ],
  },
  {
    type: 'report',
    time: '16:20',
    desc: 'Тема уточнюється',
    speakerName: 'Богдан Лукіянчук',
    speakerDesc: 'Agro Digital Holding',
    speakerImg: new URL('../assets/speakers/b_lukiyanchuk.png?as=webp&width=200', import.meta.url),
    brandImg: new URL('../assets/speakers/b_lukiyanchuk_logo.png?as=webp', import.meta.url),
  },
  {
    type: 'report',
    time: '16:40',
    desc: 'Тема уточнюється',
    speakerName: 'Сергій Суровий',
    speakerDesc: 'Фермер',
    speakerImg: new URL('../assets/speakers/suroviy.png?as=webp&width=200', import.meta.url),
    brandImg: new URL('../assets/speakers/suroviy_logo.png?as=webp', import.meta.url),
  },
  {
    type: 'report',
    time: '10:40',
    desc: 'Тема уточнюється',
    speakerName: 'Adam Mikołajczak',
    speakerDesc: 'General Manager Central Eastern Europe, eAgronom',
    speakerImg: new URL('../assets/speakers/adam.png?as=webp&width=200', import.meta.url),
    brandImg: new URL('../assets/speakers/adam_logo.png?as=webp', import.meta.url),
  },
  {
    type: 'break',
    time: '00:00',
    title: 'ПРОГРАМА ФОРМУЄТЬСЯ',
  },
];
