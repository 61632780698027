export const business = [
  {
    type: 'ad',
    img: new URL('../assets/ads/b_zemlerob_sponsor_1.gif', import.meta.url),
    height: '115px',
    url: 'https://zemlerob.com.ua/',
    time: '00:00 - 00:00',
  },
  {
    type: 'report',
    time: '11:20',
    desc: 'Сучасний ринок землі: від стратегії до результату.',
    speakerName: 'Роман Барало',
    speakerDesc: 'Генеральний директор компанії Доброзем',
    speakerImg: new URL('../assets/speakers/baralo.png?as=webp&width=200', import.meta.url),
    brandImg: new URL('../assets/speakers/baralo_logo.png?as=webp', import.meta.url),
  },
  {
    type: 'report',
    time: '15:00',
    desc: 'Як фермеру захисти свою продукцію. Що робити, якщо зерновий склад знищено',
    speakerName: 'Анна Кухарчук',
    speakerDesc: 'Керуючий партнер компанії, адвокат',
    speakerImg: new URL('../assets/speakers/annakux.png?as=webp&width=200', import.meta.url),
    brandImg: new URL('../assets/speakers/annakux_logo.png?as=webp', import.meta.url),
  },
  {
    type: 'report',
    time: '12:00',
    desc: 'Агроконсалтинг як драйвер урожайності: від точкових рішень до системного підходу',
    speakerName: 'Роман Рибак',
    speakerDesc: 'СЕО агроконсалтингової компанії ProPole',
    speakerImg: new URL('../assets/speakers/rybak.png?as=webp&width=200', import.meta.url),
    brandImg: new URL('../assets/speakers/rybak_logo.png?as=webp', import.meta.url),
  },
  {
    type: 'break',
    time: '00:00',
    title: 'ПРОГРАМА ФОРМУЄТЬСЯ',
  },
];
