export const precisionAgriculture = [
  {
    type: 'ad',
    img: new URL('../assets/ads/tz_zemlerob_sponsor.gif', import.meta.url),
    imgMobile: new URL('../assets/ads/tz_zemlerob_sponsor.gif', import.meta.url),
    height: '115px',
    heightMobile: '115px',
    url: 'https://zemlerob.com.ua/',
    time: '00:00 - 00:00',
  },
  {
    // disable: true,
    type: 'report',
    time: '11:40',
    desc: 'Фермерство 4.0 з xFarm: Як технології змінюють агробізнес',
    speakerName: 'Олександра Воловик',
    speakerDesc: 'Business Development Manager',
    speakerImg: new URL('../assets/speakers/volovik.png?as=webp&width=200', import.meta.url),
    brandImg: new URL('../assets/speakers/volovik_logo.png?as=webp', import.meta.url),
  },
  {
    // disable: true,
    type: 'report',
    time: '09:30',
    desc: 'Тема уточнюється',
    speakerName: 'Всеволод Генін',
    speakerDesc: 'Head of agricultural research, співзасновник',
    speakerImg: new URL('../assets/speakers/vsevolod.png?as=webp&width=200', import.meta.url),
    brandImg: new URL('../assets/speakers/vsevolod_logo.png?as=webp', import.meta.url),
  },
  {
    // disable: true,
    type: 'report',
    time: '11:20',
    desc: 'Тема уточнюється',
    speakerName: 'Андрій Капріца',
    speakerDesc: 'ФГ «Флора А.А.»',
    speakerImg: new URL('../assets/speakers/caprica.png?as=webp&width=200', import.meta.url),
    brandImg: new URL('../assets/speakers/caprica_logo.png?as=webp', import.meta.url),
  },
  {
    // disable: true,
    type: 'report',
    time: '10:30',
    desc: 'Тема уточнюється',
    speakerName: 'Дмитро Грушецький',
    speakerDesc: 'співвласник ПП "Дніпро"',
    speakerImg: new URL('../assets/speakers/grushetsky.png?as=webp&width=200', import.meta.url),
    brandImg: new URL('../assets/speakers/grushetsky_logo.png?as=webp', import.meta.url),
  },
  {
    // disable: true,
    type: 'report',
    time: '11:00',
    desc: 'Тема уточнюється',
    speakerName: 'Валерія Ващенко',
    speakerDesc: 'ГК «Землероб»',
    speakerImg: new URL('../assets/speakers/vaschenko.png?as=webp&width=200', import.meta.url),
    brandImg: new URL('../assets/speakers/vaschenko_logo.png?as=webp', import.meta.url),
  },
  {
    // disable: true,
    type: 'report',
    time: '16:00',
    desc: 'Тема уточнюється',
    speakerName: 'Євген Сапіженко',
    speakerDesc: 'Заступник директора агробізнесу з інноваційного та цифрового розвитку «Кернел»',
    speakerImg: new URL('../assets/speakers/sapishenko.png?as=webp&width=200', import.meta.url),
    brandImg: new URL('../assets/speakers/sapishenko_logo.png?as=webp', import.meta.url),
  },
  {
    // disable: true,
    type: 'report',
    time: '12:00',
    desc: 'Тема уточнюється',
    speakerName: 'Григорій Кобзар',
    speakerDesc: 'Продакт-менеджер Vitagro Partner з МКД, РКД та інокулянтів',
    speakerImg: new URL('../assets/speakers/kobzar.png?as=webp&width=200', import.meta.url),
    brandImg: new URL('../assets/speakers/kobzar_logo.png?as=webp', import.meta.url),
  },
  {
    // disable: true,
    type: 'report',
    time: '12:20',
    desc: 'Тема уточнюється',
    speakerName: 'Роман Горобець',
    speakerDesc: 'СФГ "АСТРА"',
    speakerImg: new URL('../assets/speakers/gorobec.png?as=webp&width=200', import.meta.url),
    brandImg: new URL('../assets/speakers/gorobec_logo.png?as=webp', import.meta.url),
  },
  {
    // disable: true,
    type: 'report',
    time: '12:40',
    desc: 'Тема уточнюється',
    speakerName: 'Дмитро Зайцев',
    speakerDesc: 'керівник служби точного землеробства',
    speakerImg: new URL('../assets/speakers/zaycev.png?as=webp&width=200', import.meta.url),
    brandImg: new URL('../assets/speakers/zaycev_logo.png?as=webp', import.meta.url),
  },
  {
    type: 'break',
    time: '00:00',
    title: 'ПРОГРАМА ФОРМУЄТЬСЯ',
  },
];
