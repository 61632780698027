export const precisionAgriculture2 = [
  {
    type: 'ad',
    img: new URL('../assets/ads/tz_zemlerob_sponsor.gif', import.meta.url),
    imgMobile: new URL('../assets/ads/tz_zemlerob_sponsor.gif', import.meta.url),
    height: '115px',
    heightMobile: '115px',
    url: 'https://zemlerob.com.ua/',
    time: '00:00 - 00:00',
  },
  {
    type: 'break',
    time: '09:00',
    title: 'ПРОГРАМА ФОРМУЄТЬСЯ',
  },
];
