export const production = [
  {
    type: 'ad',
    img: new URL('../assets/ads/virobnictvo_agroritm_sponsor.gif', import.meta.url),
    imgMobile: new URL('../assets/ads/virobnictvo_agroritm_sponsor.gif', import.meta.url),
    height: '115px',
    heightMobile: '115px',
    url: 'https://www.refarm.com.ua/',
    time: '00:00 - 00:00',
  },
  {
    type: 'report',
    time: '11:40',
    desc: 'Тема уточнюється',
    speakerName: 'Оксана Лукіянчук',
    speakerDesc: 'ФГ "КЛАПТИК СТЕПУ"',
    speakerImg: new URL('../assets/speakers/lukiyanchuk.png?as=webp&width=200', import.meta.url),
    brandImg: new URL('../assets/speakers/lukiyanchuk_logo.png?as=webp', import.meta.url),
  },
  {
    type: 'report',
    time: '12:20',
    desc: 'Як зі звичайного продукту для зважування автомобілів зробити революцію в сфері?',
    speakerName: 'Іван Мовчан',
    speakerDesc: 'СЕО Українська Вагова Компанія',
    speakerImg: new URL('../assets/speakers/movchan.png?as=webp&width=200', import.meta.url),
    brandImg: new URL('../assets/speakers/movchan_logo.png?as=webp', import.meta.url),
  },
  {
    type: 'break',
    time: '00:00',
    title: 'ПРОГРАМА ФОРМУЄТЬСЯ',
  },
];
