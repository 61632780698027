export const technology2 = [
  {
    type: 'ad',
    img: new URL('../assets/ads/tech_zemlerob_sponsor.gif', import.meta.url),
    imgMobile: new URL('../assets/ads/tech_zemlerob_sponsor.gif', import.meta.url),
    height: '115px',
    heightMobile: '115px',
    url: 'https://www.facebook.com/zemlerob.com.ua/',
    time: '00:00 - 00:00',
  },
  {
    type: 'report',
    time: '11:00',
    desc: 'Фосфорний пазл: Умови ефективності стартових добрив',
    speakerName: 'Ольга Капітанська',
    speakerDesc: 'Керівниця науково-дослідного відділу НВК «Квадрат», к.б.н',
    speakerImg: new URL('../assets/speakers/kapitanska.png?as=webp&width=200', import.meta.url),
    brandImg: new URL('../assets/speakers/kapitanska_logo.png?as=webp', import.meta.url),
  },
  {
    type: 'break',
    time: '00:00',
    title: 'ПРОГРАМА ФОРМУЄТЬСЯ',
  },
];
